<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="bussinessItems"
            type="none"
            itemText="codeName"
            itemValue="code"
            name="business"
            label="게시판 구분"
            v-model="searchParam.business"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            name="keyword"
            label="제목 키워드"
            v-model="searchParam.keyword">
          </c-text>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="한국산업안전공단 국내재해사례 게시판"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!--검색-->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'saf-hea-manage-policy',
  data() {
    return {
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        width: '60%',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      dshow: false,
      bussinessItems: [
        {
          code: '제조업',
          codeName: '제조업',
        },
        {
          code: '직업병',
          codeName: '직업병',
        },
        {
          code: '중대산업사고',
          codeName: '중대산업사고',
        },
        {
          code: '중대사고 이슈리포트',
          codeName: '중대사고 이슈리포트',
        },
      ],
      grid: {
        columns: [
          {
            name: 'business',
            field: 'business',
            label: '구분',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'keyword',
            field: 'keyword',
            // 게시년도
            label: '제목',
            align: 'left',
            type: 'link',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        business: '제조업',
        keyword: '',
        numRows: '1000000',
        pageNo: '1',
      },
      period: [],
      editable: true,
      listUrl: '',
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'SHM_POLICY',
        taskKey: '',
      },
      selectedRow: {},
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = '/api/openapi/accident/kosha';
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data.body.items.item;
      },);
    },
    linkClick(row) {
      this.popupOptions.target = () => import(`${"./koshaAccidentDetail.vue"}`);
      this.popupOptions.title = '사고사례 상세내용';   //법규등록부 및 준수평가표
      this.popupOptions.param = {
        data: row
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
<style lang="scss">
.accboradlink {
  margin-bottom: 10px;
}
.fixheaderCard {
  position: fixed !important;
  right: 10px;
  bottom: 20px;
}
</style>
